<div class="container-fluid p-0 h-100">
  <div class="row no-gutters h-100">
    <div class="col-lg-4 d-lg-flex">
      <div class="d-flex h-100 p-h-40 p-v-15 flex-column">
        <div><img class="m-50" src="assets/images/logo/logo.svg" alt=""></div>
        <div><h1 class="m-b-20 m-t-50 font-weight-normal">Kostenloser Zugang für <strong>Hausverwaltungen</strong></h1>

          <p class="font-size-16 lh-2 w-80 opacity-08">Wenn Sie als Hausverwaltung mit Ihren Hausmeistern zusammenarbeiten möchten, können Sie Ihre Hausverwaltung hier <strong>kostenlos</strong> registrieren.</p>
          <p class="font-size-16 lh-2 w-80 opacity-08">Nach einer individuellen überprüfung Ihrer Daten erhalten Sie Ihre Zugangsdaten per E-Mail.</p>

          <p class="font-size-16 lh-2 w-80 opacity-08">
            <a routerLink="/application">Hier</a> get es zur Registrierung für Hausmeister.
          </p>

        </div>

      </div>
    </div>
    <div class="col-lg-8 bg-white">
      <div class="container h-100 p-h-40">
        <div class="row no-gutters h-100 align-items-center">
          <div class="col-md-8 col-lg-7 col-xl-6 mx-auto m-t-20">
            <form nz-form nzLayout="vertical" [formGroup]="signUpForm" class="login-form" (ngSubmit)="submitForm()">
              <h3>Angaben zur Hausverwaltung</h3>
              <div nz-row>
                <nz-form-item nz-col nzSpan="24">
                  <nz-form-label nzRequired nzFor="company_name">Name der Hausverwaltung</nz-form-label>
                  <nz-form-control>
                    <input type="text" nz-input formControlName="company_name" placeholder="Hausverwaltung Mustermann"
                           id="company">
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-row>
                <nz-form-item nz-col nzSpan="24">
                  <nz-form-label nzRequired nzFor="address">
                    Adresse in Deutschland
                  </nz-form-label>
                  <nz-form-control>
                    <input formControlName="address" nz-input placeholder="Musterstraße 7a">
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-row>
                <nz-form-item nz-col nzSpan="12" class="p-r-5">
                  <nz-form-label nzRequired nzFor="zip">PLZ</nz-form-label>
                  <nz-form-control>
                    <input formControlName="zip" nz-input placeholder="PLZ">
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col nzSpan="12">
                  <nz-form-label nzRequired nzFor="city">Ort</nz-form-label>
                  <nz-form-control>
                    <input formControlName="city" nz-input placeholder="Ort">
                  </nz-form-control>
                </nz-form-item>
              </div>
              <nz-divider></nz-divider>
              <h3>Ansprechpartner</h3>
              <div nz-row>
                <nz-form-item nz-col nzSpan="12" class="p-r-5">
                  <nz-form-label nzRequired nzFor="firstname">Vorname</nz-form-label>
                  <nz-form-control>
                    <input type="text" nz-input formControlName="firstname" placeholder="Max">
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-col nzSpan="12">
                  <nz-form-label nzRequired nzFor="firstname">Nachname</nz-form-label>
                  <nz-form-control>
                    <input type="text" nz-input formControlName="lastname" placeholder="Mustermann">
                  </nz-form-control>
                </nz-form-item>
              </div>
              <nz-form-item>
                <nz-form-label nzRequired nzFor="email">E-Mailadresse</nz-form-label>
                <nz-form-control>
                  <input type="text" nz-input formControlName="email"
                         placeholder="mustermann@hausverwaltung-mustermann.de" id="email">
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label nzRequired nzFor="phone">Telefonnummer unter der wir Sie erreichen können*
                </nz-form-label>
                <nz-form-control>
                  <input type="text" nz-input formControlName="phone" placeholder="+49 1234 123456" id="phone">
                </nz-form-control>
              </nz-form-item>

              <p>
                Hier finden Sie unsere Informationen zum <a href="https://hausmeisterapp.com/datenschutz/" target="_blank"
                                                            >Datenschutz</a>.
              </p>
              <nz-form-item>
                <label nz-checkbox formControlName="privacy">
                  Ich willige ein, dass meine Angaben
                  gespeichert werden, um mit mir in Kontakt treten zu dürfen. Ich habe die Datenschutzerklärung
                  gelesen und stimme dieser zu.
                </label>
              </nz-form-item>


              <div class="d-flex align-items-center justify-content-between">
                <nz-form-item>
                  <nz-form-control>
                    <div>
                      <button nz-button class="login-form-button" [disabled]="signUpForm.invalid || disabled" [nzType]="'primary'">
                        Anfrage senden
                      </button>
                    </div>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
