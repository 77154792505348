import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Application} from '../../../../../database-models';
import {ApplicationService} from '../../shared/services/applicationService';
import {ActivatedRoute, Router} from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-hv-registration',
  templateUrl: './hv-registration.component.html',
  styleUrls: ['./hv-registration.component.css']
})
export class HvRegistrationComponent implements OnInit {

  public signUpForm: FormGroup;
  public application = {} as Application;
  public disabled = false;

  constructor(private fb: FormBuilder,
              public formBuilder: FormBuilder,
              public applicationService: ApplicationService,
              public route: ActivatedRoute,
              public router: Router,
              private message: NzMessageService,
              private modalService: NzModalService,
              private notification: NzNotificationService
  ) {
  }

  public ngOnInit(): void {
    this.signUpForm = this.fb.group({
      company_name: [null, [Validators.required]],
      firstname: [null, [Validators.required]],
      lastname: [null, [Validators.required]],
      address: [null, [Validators.required]],
      zip: [null, [Validators.required]],
      city: [null, [Validators.required]],
      country: ['Deutschland', [Validators.required]],
      email: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      employees: [1, [Validators.required, Validators.min(1)]],
      privacy: [false, [Validators.requiredTrue]]
    });
  }

  submitForm(): void {
    const sendData = this.signUpForm.getRawValue();
    sendData.type = 'property_management';
    this.disabled = true
    this.applicationService.applicate(sendData).subscribe((application: Application) => {
      this.notification.create(
          'success',
          'Email Gesendet',
          'Ihre Email wurde erfolgreich gesendet.',
          {nzDuration: 0});
      this.disabled = false;
      this.router.navigate(['application/success']);
    }, error => {
      this.notification.create(
        'error',
        'Senden fehlgeschlagen! Bitte versuchen Sie es erneut.',
        error.error
      );
    });
  }


}

